import '@fontsource-variable/expletus-sans';
import Cookies from 'js-cookie';
import 'core-js';
import 'admin/plugins/flatpickr';
import 'public/main'
import 'public/style.scss'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

