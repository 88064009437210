// import AOS from 'aos';

// JavaScript
$(document).ready( function () {
  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  //form input
  document.querySelectorAll('input, select, textarea').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length >= 0) {
        input.classList.add('focused')
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      };
    });

    input.addEventListener('focus', () => {
      input.classList.add('focused')
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    });

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.classList.remove('focused')
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      };
    });
  });
});
